<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">직원 상세</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>권한</th>
          <td>
            <span class="txt_tbl">
              <Badge
                :text="viewModel.model.role | convertIdToText('member_role')"
                :badgeStyle="viewModel.model.role | convertIdToColor('member_role')"
                :badgeFill="false" />
            </span>
          </td>
        </tr>
        <tr>
          <th>로그인상태</th>
          <td>
            <IconSvg
              :iconName="$options.filters.convertIdToText(viewModel.model.loginStatus, 'member_login_status_icon')" :size="16"
              :iconColor="$options.filters.convertIdToColor(viewModel.model.loginStatus, 'member_login_status_icon')"
              :style="{margin: '10px 4px 0 0'}"/>
            <span :class="['txt_tbl',$options.filters.convertIdToColor(viewModel.model.loginStatus, 'member_login_status', 'tc')]">{{ viewModel.model.loginStatus | convertIdToText('member_login_status') }}</span>
            <div
              class="area_right">
              <Button
                v-if="viewModel.model.loginStatus === 'LOCKED'"
                btnSize="small"
                btnStyle="primary_border"
                text="로그인 잠금해제"
                @onClickBtn="viewModel.onClickUnlock()">
                <IconSvg
                  iconName="login_ok"
                  :size="16"
                  iconColor="#216BD9"/>
              </Button>
              <Button
                v-else
                btnSize="small"
                btnStyle="fourthly_border"
                text="로그인 잠금"
                @onClickBtn="viewModel.onClickLock()">
                <IconSvg
                  iconName="login_lock"
                  :size="14"
                  iconColor="#F04848"/>
              </Button>
            </div>
          </td>
        </tr>
        <tr>
          <th>이미지</th>
          <td>
            <div class="img_profile">
              <img
                :src="viewModel.model.imageList.length > 0 ? viewModel.model.imageList[0].src : ''"
                class="img_g"
                alt="profile"
                onerror="this.src='/assets/images/profile_default.png'">
            </div>
          </td>
        </tr>
        <tr>
          <th>이름</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.name }}</span>
          </td>
        </tr>
        <tr>
          <th>이메일</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.email }}</span>
          </td>
        </tr>
        <tr>
          <th>생성일시</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.createdAt | convertDateFormat }}</span>
          </td>
        </tr>
        <tr>
          <th>수정일시</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.updatedAt | convertDateFormat }}</span>
          </td>
        </tr>
        <tr>
          <th>마지막 로그인 일시</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.lastLoginAt | convertDateFormat }}</span>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록"
          routeName="ADMIN_MEMBER_LIST"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="fourthly"
          text="삭제"
          @onClickBtn="viewModel.onClickDelete()"/>
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="수정"
          routeName="ADMIN_MEMBER_MODIFY"
          :routeParams="{ id : viewModel.model.id }"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import MemberDetailViewModel from '@/views/admin/member/viewModel/MemberDetailViewModel'

export default {
  name:'MemberDetail',
  components:{
    PageWithLayout,
    TableView,
    Badge,
    IconSvg,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id
    this.viewModel.init(id);
  },
  data(){
    return{
      viewModel: new MemberDetailViewModel(),
    }
  },
}
</script>
<style scoped>
.img_profile{overflow:hidden;width:150px;border-radius:6px}
</style>