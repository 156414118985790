import axios from 'axios';

const isProductionMode = process.env.NODE_ENV === 'production';
const devPath = 'https://lemontree-content-data-dev.s3.ap-northeast-2.amazonaws.com';
const productionPath = 'https://lemontree-content-data-prod.s3.ap-northeast-2.amazonaws.com';

const http = axios.create({
  headers: {
    'Accept':'image/*',
    'Content-Type':'image/jpeg'
  },
  proxy: {
    "/images": {
      target: isProductionMode ? productionPath : devPath,
      changeOrigin: true
    },
  }
});
axios.defaults.withCredentials = true;

http.interceptors.request.use(
  function(config) {

    checkConfig(config);
    return config;
  },
  function(error) {
    console.log('error Promise',error);
    return Promise.reject(error);
  },
);

function checkConfig(config) {
  // const token = LocalStorageManager.shared.getToken() || config.headers.Authorization;
  // if (!token) {
  //   router.push({ name : "LoginDirect" }).catch(()=>{});
  // }else{
  //   config.headers['Authorization'] = `Bearer ${token}`;
  // }
}

http.interceptors.response.use(
  async function(response) {
    return response;
  },
  function(error) {
    // // -20010 ~ -20020
    // if(error.response.data.code <= -20020 && error.response.data.code >= -20010){
    //   router.push({ name : "LoginDirect" }).catch(()=>{});
    //   LocalStorageManager.shared.clearToken();
    //   LocalStorageManager.shared.clearUserData();
    //   store.dispatch('commonUserData/fetchUserData', {});
    //   return Promise.reject(error);
    // }
    // // -20003
    // else if(error.response.data.code === -20003){
    //   router.push({ name : "LoginDirect" }).catch(()=>{});
    //   store.dispatch('passowrdChangeError/fetchPasswordChangeErrorStart');
    //   return Promise.reject(error);
    // }
    // else{
    //   return Promise.reject(error);
    // }
    return Promise.reject(error);
  },
);

export function GET_S3_REQUEST(path) {
  return new Promise((success, failed) => {
    http.get(path).then((response) => {
      if (response.status === 200) {
        const data = response.data
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message
        const errCode = '200'
        const errMsg = 'errMsg'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg
          }
          failed(result)

        }else{

          let result = {
            data : data
          }
          success(result)
          // console.log(result)
        }
      }

    }).catch((err) => {
      let result = {
        errCode: err.response.data.code,
        msg: err.response.data.message.toString()
      }
      failed(result)
    })
  })
}

export function PUT_S3_REQUEST(path, data, config) {
  return new Promise((success, failed) => {
    http.put(path, data, config).then((response) => {
      if (response.status === 200) {
        const data = response.data
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message
        // const description = data.header.result.description
        const errCode = '200'
        const errMsg = 'errMsg'
        const description = 'description'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg + '\n' + description
          }
          failed(result)

        }else{

          let result = {
            data : data.body
          }
          success(result)
          // console.log(result)
        }
      }

    }).catch((err) => {
      // console.log(err.toString())
      let result = {
        errCode: err.response.data.code,
        msg: err.response.data.message.toString()
      }
      failed(result)

    })
  })
}

export function POST_S3_REQUEST(path, data, config) {
  return new Promise((success, failed) => {
    http.post(path, data, config).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message
        const errCode = '200'
        const errMsg = 'errMsg'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg
          }
          failed(result)

        }else{

          let result = {
            data : data
          }
          success(result)
          // console.log(result)
        }
      }

    }).catch((err) => {
      // console.log(err.toString())
      let result = {
        errCode: err.response.data.code,
        msg: err.response.data.message.toString()
      }
      failed(result)

    })
  })
}

export function DELETE_S3_REQUEST(path) {
  return new Promise((success, failed) => {
    http.delete(path).then((response) => {
      if (response.status === 200) {
        const data = response.data
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message
        const errCode = '200'
        const errMsg = 'errMsg'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg
          }
          failed(result)

        }else{

          let result = {
            data : data.body
          }
          success(result)
          // console.log(result)
        }
      }

    }).catch((err) => {
      // console.log(err.toString())
      let result = {
        errCode: err.response.data.code,
        msg: err.response.data.message.toString()
      }
      failed(result)

    })
  })
}

export default http;
